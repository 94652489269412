
import { Component, Vue } from "vue-property-decorator";
import { PButton } from "@/common/components";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  name: "button-back-to-dashboard",
  components: {
    PButton,
    PIcon,
  },
})
export class ButtonBackToDashboard extends Vue {
  constructor() {
    super();
  }
}
export default ButtonBackToDashboard;
