import { render, staticRenderFns } from "./PFormSelect.vue?vue&type=template&id=34cff142&scoped=true&"
import script from "./PFormSelect.vue?vue&type=script&lang=ts&"
export * from "./PFormSelect.vue?vue&type=script&lang=ts&"
import style0 from "./PFormSelect.vue?vue&type=style&index=0&id=34cff142&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34cff142",
  null
  
)

export default component.exports