
import { DenominationData, DenominationFromCurrencyCode, DenominationType } from "@/orders";
import { Component, Prop, Vue } from "vue-property-decorator";
import ComparatorInput from "@/orders/components/ComparatorInput/ComparatorInput.vue";
import Utils from "@/orders/components/Commons/utils";
import PDropdown from "@/orders/components/Dropdown/Dropdown.vue";
import IconX from "@/common/components/look/PIcon/icon/IconX.vue";

@Component({
  components: {
    PDropdown,
    ComparatorInput,
    IconX,
  },
})
export default class TableDelivery extends Vue {
  @Prop({ required: true }) denominationsFromCurrency!: DenominationFromCurrencyCode;
  @Prop({ required: true }) currenciesCode!: string[];
  @Prop({ required: true }) currentPack!: string;

  currentPackTranslate = "";

  created(): void {
    this.evaluateCurrentPackTranslate();
  }

  currencyFormat(amount: number): string {
    return Utils.currencyFormat(amount);
  }

  addExpand(id: string): void {
    const denominationsData = this.denominationsFromCurrency.denominations;
    const found = denominationsData.find((denomination) => denomination.id === id);
    if (found) {
      found.expand = false;
      this.$emit("updateDenominations", this.denominationsFromCurrency);
    }
  }

  onChangeCurrency(currencyCode: string): void {
    this.$emit("changeCurrency", currencyCode);
  }

  onDeleteDenominations(): void {
    const currencyCode = this.denominationsFromCurrency.currencyCode;
    this.$emit("deleteDenominations", currencyCode);
  }

  get denominations(): DenominationData[] {
    return this.denominationsFromCurrency.denominations.filter(
      (denomination) => denomination.type === this.currentPackTranslate
    );
  }

  evaluateCurrentPackTranslate() {
    switch (this.currentPack) {
      case DenominationType.BILLS.value:
        this.currentPackTranslate = DenominationType.BILLS.description;
        break;
      case DenominationType.COINS.value:
        this.currentPackTranslate = DenominationType.COINS.description;
        break;
      default:
        this.currentPackTranslate = this.currentPack;
        break;
    }
  }
}
