
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export class Toast extends Vue {
  @Prop({ required: false }) width!: number;

  toggle = false;
  isTooltipOverflowing = false;
  isNearBottom = false;
  tooltipWidth = 0;
  tooltipStyle = {};

  handleMouseOver() {
    this.toggle = true;
    this.$nextTick(() => {
      const tooltip = this.$el.querySelector(".toast__content");
      const containerRect = this.$el.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if (tooltip instanceof HTMLElement && containerRect) {
        this.tooltipWidth = tooltip.offsetWidth;
        this.isTooltipOverflowing = containerRect.right + this.tooltipWidth + 60 > windowWidth;
        this.isNearBottom = containerRect.bottom + tooltip.offsetHeight + 130 > windowHeight;

        if (this.isNearBottom) {
          this.tooltipStyle = { top: "auto", bottom: "calc(100% + 100px)" };
        } else {
          this.tooltipStyle = { top: "50%", bottom: "auto" };
        }
      }
    });
  }
}
export default Toast;
