
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType } from "@/common/components";
import PFormSelectNarrow from "./PFormSelectNarrow.vue";
import PFormCheckBox from "../PFormCheckBox/PFormCheckBox.vue";
import PButton from "../PButton/PButton.vue";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PFormSelectTranslations, translations } from "./pFormSelectLanguages";
import { PFormSelectMultiFilterTranslation } from "./pFormSelect.types";

@Component({
  components: {
    PFormCheckBox,
    PFormSelectNarrow,
    PDropDownContainer,
    PButton,
    PIcon,
  },
})
export class PFormSelectMultiFilter extends Vue {
  @Prop({ required: true }) value!: string[];
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.fillValueTempInitial();
    this.fillViewInput();
  }
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: true }) filterProperties!: SelectType[];
  @Prop({ required: false, default: undefined }) state!: boolean;
  @Prop({ required: false, default: undefined }) invalidFeedback!: string;
  @Prop({ required: false }) translation!: PFormSelectMultiFilterTranslation;
  @Prop({ required: false, default: "es" }) language!: string;
  @Watch("pussOption")
  onChangePussOption(): void {
    if (this.pussOption) {
      this.fillValueTempInitial();
      this.fillViewInput();
    }
  }
  @Watch("options")
  onChangeOptions(): void {
    this.emptyValueTemp();
    this.fillValueTempInitial();
    this.fillViewInput();
  }
  @Watch("allCheck")
  onAllCheck(): void {
    this.selectAll();
  }

  selectItemFilterProperty = "";
  allCheck = false;
  valueTemp: string[] = [];
  viewInput = { firstSelectedText: "", more: "" };
  filterOptions = "";
  pussOption = false;
  translator: PFormSelectTranslations = translations[0].translations;

  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }
  mounted(): void {
    if (this.filterProperties.length > 0) {
      this.selectItemFilterProperty = this.filterProperties[0].value;
    }
  }

  get placeholderSearch(): string {
    const filterOn = this.filterProperties.find((filter) => filter.value === this.selectItemFilterProperty);
    if (filterOn) {
      return this.translator.textFilterOn + " " + filterOn.text;
    } else {
      return this.translator.textSelectWhatFilterBy;
    }
  }
  get optionsComp(): SelectType[] {
    if (this.filterOptions)
      return this.options.filter((op) => op.text.toUpperCase().includes(this.filterOptions.toUpperCase()));

    return this.options;
  }

  changeOptions(): void {
    this.allCheck = this.areAllChecked(this.valueTemp);
  }
  areAllChecked(arr: string[]): boolean {
    return this.options.length == arr.filter((v) => v != "").length;
  }
  fillValueTempInitial(): void {
    this.options.forEach((op, index) => {
      if (this.value.findIndex((val) => val === op.value) > -1) {
        this.$set(this.valueTemp, index, op.value);
      } else {
        this.$set(this.valueTemp, index, "");
      }
    });
    this.allCheck = this.areAllChecked(this.valueTemp);
  }
  emptyValueTemp(): void {
    this.unSelect();
  }

  fillViewInput(): void {
    this.viewInput.more = "";
    this.viewInput.firstSelectedText = this.translator.textNoFilter;
    const valueTempFill = this.valueTemp.filter((val) => val != "");
    if (valueTempFill.length > 0) {
      const optionsFill = this.options.find((op) => op.value == valueTempFill[0]);
      this.viewInput.firstSelectedText = optionsFill ? " " + optionsFill.text : this.translator.textNoFilter + "";
    }
    if (valueTempFill.length > 1) {
      this.viewInput.more = " +" + (valueTempFill.length - 1) + " " + this.translator.textMore;
    }
  }
  search(str: string): void {
    const index = this.options.findIndex((op) => op.value == str);
    if (index > -1) {
      this.$set(this.valueTemp, index, this.options[index].value);
    } else {
      console.error("Options de PFormSelectMultiFilter no coinciden con los del _PFormSelectMultiFilter ");
    }
  }
  showOptions(): void {
    this.pussOption = true;
  }
  apply(): void {
    this.pussOption = false;
    this.fillViewInput();
    this.$emit("input", [...this.valueTemp]);
  }
  unSelect(): void {
    this.options.forEach((op, index) => {
      this.$set(this.valueTemp, index, "");
    });
    this.allCheck = false;
  }
  selectAll(): void {
    if (this.allCheck) {
      this.optionsComp.forEach((op, index) => {
        this.$set(this.valueTemp, index, op.value);
      });
    } else {
      if (this.areAllChecked(this.valueTemp)) {
        this.unSelect();
      } else {
        this.optionsComp.forEach((op, index) => {
          if (!op) this.$set(this.valueTemp, index, "");
        });
      }
    }
  }
}
export default PFormSelectMultiFilter;
