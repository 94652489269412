
import { Component, Vue, Prop } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
@Component({
  components: {
    PIcon,
  },
})
export class PButton extends Vue {
  @Prop({ required: false, default: "primary" }) variant!: string;
  @Prop({ required: false, default: false }) disabled!: boolean;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: false }) isActive!: boolean;
  get variantCop(): string {
    let returnTemp: string = "p-button-" + this.variant;
    if (this.isActive) {
      returnTemp = returnTemp + " active_PButton";
    }
    return returnTemp;
  }
}
export default PButton;
