
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import draggable from "vuedraggable";

interface ItemPDragAndDrop {
  [key: string]: unknown;
  label: string;
}

@Component({ components: { PIcon, draggable } })
export class PDragAndDrop extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) value!: ItemPDragAndDrop[];
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.valueLocal = [];
    this.value.forEach((fi: ItemPDragAndDrop) => {
      this.valueLocal.push({ ...fi });
    });
  }

  drag = false;
  valueLocal: ItemPDragAndDrop[] = [];

  change(): void {
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }
}
export default PDragAndDrop;
