
import Icon from "@/orders/components/Icon/index.vue";
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import { Entity, ServicePoint } from "@/orders";
import { Getter } from "vuex-class";

@Component({
  components: {
    Icon,
  },
})
export class Entities extends Vue {
  @Getter("getCashTodayServicePointsOrders") getCashTodayServicePoints!: ServicePoint[];
  @Model("value") filteredEntities!: Entity[];
  @Prop({ required: true }) items!: Entity[];

  eyes = require("@/orders/assets/icons/icon-eye.svg");
  selectedItems: Entity[] = [];
  filterItems: Entity[] = [];
  searchTerm = "";
  isSelectAll = true;
  isSelectAllCashToday = false;

  created(): void {
    this.filterItems = this.getFilterItems();
    this.selectedItems = [...this.filteredEntities];
  }

  selectOrUnselectAll(): void {
    if (this.cashTodayEntities.length === this.filterItems.length) {
      this.isSelectAllCashToday = this.isSelectAll;
      this.selectedItems = this.isSelectAll ? [...this.filterItems] : [];
      return;
    }

    if (this.cashTodayEntities.length > 0) {
      this.isSelectAllCashToday = !this.isSelectAll;
    }

    this.selectedItems = this.isSelectAll ? [...this.filterItems] : [];
  }

  selectOrUnselectAllCashToday(): void {
    if (this.cashTodayEntities.length === this.filterItems.length) {
      this.isSelectAll = this.isSelectAllCashToday;
      this.selectedItems = this.isSelectAll ? [...this.filterItems] : [];
      return;
    }

    this.isSelectAll = !this.isSelectAllCashToday;

    this.selectedItems = this.isSelectAllCashToday ? [...this.cashTodayEntities] : [...this.filterItems];
  }

  @Watch("selectedItems")
  onChangeItemsSelected(): void {
    this.isSelectAll = this.selectedItems.length == this.filterItems.length;
    if (this.cashTodayEntities.length > 0) {
      this.isSelectAllCashToday = this.areEntitiesEqual(this.selectedItems, this.cashTodayEntities, "id");
    }
    this.$emit("value", this.selectedItems);
  }

  areEntitiesEqual<K extends keyof Entity>(firstArray: Entity[], secondArray: Entity[], attribute: K): boolean {
    if (firstArray.length !== secondArray.length) {
      return false;
    }

    const firstArrayAttributes = firstArray.map((item) => item[attribute]);
    const secondArrayAttributes = secondArray.map((item) => item[attribute]);

    const firstSortedArray = [...firstArrayAttributes].sort();
    const secondSortedArray = [...secondArrayAttributes].sort();

    return firstSortedArray.every((element, index) => element === secondSortedArray[index]);
  }

  get cashTodayEntities(): Entity[] {
    return [
      ...new Map(
        this.getCashTodayServicePoints.map((value) => value.entity).map((item) => [item["code"], item])
      ).values(),
    ];
  }

  getFilterItems(): Entity[] {
    return this.items.filter((item: Entity) => {
      const searchTermLowerCase = this.searchTerm.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchTermLowerCase) ||
        item.code.toLowerCase().includes(searchTermLowerCase) ||
        item.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.searchTerm.toLowerCase()) >= 0 ||
        item.code
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.searchTerm.toLowerCase()) >= 0
      );
    });
  }
}

export default Entities;
