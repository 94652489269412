
import { Component, Vue, Prop } from "vue-property-decorator";
import { SelectType } from "@/common/components";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class PFormInputPredictiveSearch extends Vue {
  @Prop({ required: true }) propertySearch!: string;
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false, default: "Without result" }) textEmpty!: string;

  textSearch = "";
  optionsFilter: SelectType[] = [];
  viewOptions = false;

  selectItem(option: SelectType): void {
    this.textSearch = "";
    this.viewOptions = false;

    this.$emit("search", option.value);
  }
  onInput(): void {
    if (this.textSearch.length > 1) {
      this.viewOptions = true;
      this.optionsFilter = this.options.filter((op) =>
        op[this.propertySearch].toUpperCase().includes(this.textSearch.toUpperCase())
      );
    } else {
      this.viewOptions = false;
    }
  }
}
export default PFormInputPredictiveSearch;
