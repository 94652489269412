
import { Component, Vue, Prop } from "vue-property-decorator";
import { ServicePointBalance } from "@/entities";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export default class ServicePointDetailHeader extends Vue {
  @Prop() public servicePointBalance!: ServicePointBalance;
}
