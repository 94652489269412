import { render, staticRenderFns } from "./stepPickup.vue?vue&type=template&id=3e6dc51e&scoped=true&"
import script from "./stepPickup.vue?vue&type=script&lang=ts&"
export * from "./stepPickup.vue?vue&type=script&lang=ts&"
import style0 from "./stepPickup.vue?vue&type=style&index=0&id=3e6dc51e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e6dc51e",
  null
  
)

export default component.exports