
import Icon from "@/orders/components/Icon/index.vue";
import Dropdown from "@/orders/components/Dropdown/Dropdown.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { OrderDTO, OrderSupplyData, RequestedSupply, Supply } from "@/orders";
import IconX from "@/common/components/look/PIcon/icon/IconX.vue";

@Component({
  components: {
    Icon,
    IconX,
    Dropdown,
  },
})
export default class StepSupply extends Vue {
  @Prop({ required: true }) value!: OrderSupplyData;
  @Prop({ required: true, default: null }) notChangeOrder!: OrderDTO;
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  supplies: Supply[] = [];
  supplyDescriptions: string[] = [];
  allSupplyDescriptions: string[] = [];
  materialDescription = "";
  materialType = "";
  quantity = 0;

  @Watch("value", { deep: true })
  onValueChange(): void {
    this.$emit("change", this.value);
  }

  addNewItem(): void {
    const found = this.supplies.find(
      (item) => item.supplyDescription === this.materialDescription && item.supplyTypeDescription === this.materialType
    );
    if (found) {
      const newItem: RequestedSupply = {
        supply: found,
        quantity: this.quantity,
      };
      this.value.requestedSupplies.push(newItem);
    }
    this.updateAvailableSupplies();
    this.resetData();
  }

  async created() {
    const countryCode = this.notChangeOrder.country.code;
    this.supplies = await this.$services.order.fetchSupplies(countryCode);
    this.allSupplyDescriptions = this.supplies.map((item) => item.supplyDescription) || [];
    this.supplyDescriptions = this.allSupplyDescriptions;
    this.updateAvailableSupplies();
    this.$emit("change", this.value);
  }

  get typeMaterialDescriptions() {
    const material = this.supplies.filter((item) => item.supplyDescription === this.materialDescription);
    return material.map((item) => item.supplyTypeDescription) || [];
  }

  onDeleteMaterialDescriptions(supplyDescription: string) {
    this.value.requestedSupplies = this.value.requestedSupplies.filter(
      (item) => item.supply.supplyDescription !== supplyDescription
    );
    const deletedSupply = this.allSupplyDescriptions.find((item) => item === supplyDescription);
    if (deletedSupply) {
      this.supplyDescriptions.push(deletedSupply);
    }
    this.updateAvailableSupplies();
  }

  updateAvailableSupplies() {
    const requestedSupplyDescription = new Set(
      this.value.requestedSupplies.map((item) => item.supply.supplyDescription)
    );
    this.supplyDescriptions = this.allSupplyDescriptions.filter((item) => !requestedSupplyDescription.has(item));
  }

  resetData() {
    this.materialDescription = "Seleccionar";
    this.materialType = "";
    this.quantity = 0;
  }
}
