
import ModalCancellation from "@/orders/components/ModalCancelation/ModalCancellation.vue";
import ModalUpdate from "@/orders/components/ModalUpdate/ModalUpdate.vue";
import Icon from "@/orders/components/Icon/index.vue";
import PRating from "@/orders/components/Aside/Rating/PRating.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CancellationReason,
  CreateOrderAssistanceData,
  CreateOrderDeliveryChangeData,
  CreateOrderDeliveryData,
  CreateOrderPickupData,
  CreateOrderSupplyData,
  Order,
  OrderDTO,
  OrderStatus,
  RequestedDenomination,
} from "@/orders";
import { Getter } from "vuex-class";
import VueOrders from "@/orders/vue-orders";
import DateTimeMixin from "@/mixins/datetime";
import { RequestedCurrency, RequestedCurrencyData } from "@/orders/entities/requestedCurrency";

interface DenominationFormat {
  code: string;
  symbol: string;
  total: number;
}
@Component({
  components: {
    Icon,
    PRating,
    ModalCancellation,
    ModalUpdate,
  },
  mixins: [VueOrders, DateTimeMixin],
})
export class PAside extends Vue {
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) order!: Order;
  @Getter("getStatuses") getStatuses!: OrderStatus[];
  orderDTO = {} as OrderDTO;

  eyes = require("@/orders/assets/icons/icon-eye-off.svg");
  statusExpand = false;
  openCancellation = false;
  openUpdate = false;
  cancellationReasons: CancellationReason[] = [];

  async created(): Promise<void> {
    if (!this.notIsOrderCancelled) {
      this.cancellationReasons = await this.$services.order.fetchCancellationReasons(this.order.country.code);
    }

    const _order = this.order;
    this.orderDTO = {
      commodity: _order.commodity,
      servicePoint: _order.servicePoint,
      country: _order.country,
      orderId: _order.orderId,
    };
    const commodity = this.orderDTO.commodity;

    switch (commodity.name) {
      case "PICKUP":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedCurrencies: this.toRequestedCurrencyData(_order.requestedCurrencies),
          hasPickupDeclared: _order.hasPickupDeclared,
        } as CreateOrderPickupData;
        break;
      case "ASSISTANCE":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          assistanceFailureId: _order.assistanceFailure!.id,
        } as CreateOrderAssistanceData;
        break;
      case "DELIVERY":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedDenominations: _order.requestedDenominations,
          requestedDenominationPacks: _order.requestedDenominationPacks,
        } as CreateOrderDeliveryData;
        break;
      case "DELIVERY_CHANGE":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedDenominations: _order.requestedDenominations,
          requestedDenominationPacks: _order.requestedDenominationPacks,
        } as CreateOrderDeliveryChangeData;
        break;
      case "SUPPLY":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedSupplies: _order.requestedSupplies,
        } as CreateOrderSupplyData;
        break;
      default:
        console.error("Commodity not valid");
    }
  }

  closeModalOnCancellation() {
    this.openCancellation = false;
  }

  closedModalUpdate() {
    this.openUpdate = false;
  }

  async closeModalOnUpdate() {
    await this.$store.dispatch("fetchOrders");
    this.openUpdate = false;
  }

  closeModal() {
    this.$emit("closeModal");
  }

  openTripulation() {
    this.$emit("openTripulation", this.order);
  }

  formatPrice(price: number): string {
    return price.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  formatStateDate(code: string, order: Order): string {
    const dateString: string = this.namedStatus(code, order).date || "";
    return dateString === "" ? "" : this.formatDateSimple(dateString);
  }
  formatDateSimple(date: string): string {
    const dateToFormat = this.convertISODateStringToDate(date);
    return this.formatDateTime(dateToFormat, "dd/MM/yyyy")!;
  }

  sumTotalsByCode(items: RequestedDenomination[]): DenominationFormat[] {
    const resultMap: { [key: string]: { symbol: string; total: number } } = {};

    items.forEach((item) => {
      const { code, symbol } = item.denomination.currency;
      if (resultMap[code]) {
        resultMap[code].total += item.total;
      } else {
        resultMap[code] = { symbol, total: item.total };
      }
    });

    return Object.entries(resultMap).map(([code, { symbol, total }]) => ({ code, symbol, total }));
  }

  get statuses(): OrderStatus[] {
    return this.getStatuses;
  }

  get notIsOrderCancelled(): boolean {
    return this.order.orderStatus.code !== "CAN";
  }

  get isOrderToUpdate(): boolean {
    return this.order.orderStatus.code == "PEN";
  }

  get reasonCancellation(): string {
    const reasonId = this.order.cancellationReason;
    return this.cancellationReasons.find((reason) => reason.id === reasonId)?.description || "";
  }

  get isOrderExecuted(): boolean {
    return this.order.orderStatus.code === "EXD";
  }

  toRequestedCurrencyData(requestedCurrencies: RequestedCurrency[]): RequestedCurrencyData[] {
    if (!requestedCurrencies) {
      return [];
    }
    return requestedCurrencies.map((value) => {
      return {
        currency: value.currency,
        quantity: value.total,
      };
    });
  }

  getTotalDenominations(order: Order) {
    const combinedCurrencies = [
      ...order.requestedDenominationPacks.map((item) => ({
        total: item.total,
        code: item.denominationPack.currency.code,
        symbol: item.denominationPack.currency.symbol,
        quantity: item.quantity,
      })),
      ...order.requestedDenominations.map((item) => ({
        total: item.total,
        code: item.denomination.currency.code,
        symbol: item.denomination.currency.symbol,
        quantity: item.quantity,
      })),
    ];
    return combinedCurrencies.reduce((acc, { total, code, symbol, quantity }) => {
      const currency = acc.find((curr) => curr.code === code);
      if (currency) {
        currency.total += total;
        currency.quantity += currency.quantity;
      } else {
        acc.push({ total, code, symbol, quantity });
      }
      return acc;
    }, [] as { total: number; code: string; symbol: string; quantity: number }[]);
  }

  getRequestedCurrencies(order: Order) {
    return order.requestedCurrencies.map((currencyItem) => ({
      total: currencyItem.total,
      code: currencyItem.currency.code,
      symbol: currencyItem.currency.symbol,
    }));
  }

  isCommodityWithDetail(order: Order) {
    const validCommodities = ["DELIVERY", "DELIVERY_CHANGE", "PICKUP"];
    return validCommodities.includes(order.commodity.name);
  }

  showDetail(item: Order): string | Array<any> {
    switch (item.commodity.name) {
      case "DELIVERY":
      case "DELIVERY_CHANGE": {
        return this.getTotalDenominations(item);
      }
      case "PICKUP": {
        return this.getRequestedCurrencies(item);
      }
      default:
        return "";
    }
  }
}
export default PAside;
