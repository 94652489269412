
import PDropdown from "@/orders/components/Dropdown/Dropdown.vue";
import Utils from "../../Commons/utils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Commodity, Currency, OrderDTO, OrderPickupData } from "@/orders";
import { RequestedCurrencyData } from "@/orders/entities/requestedCurrency";
import Icon from "@/orders/components/Icon/index.vue";
import { PFormInputText } from "@/common/components";
import IconX from "@/common/components/look/PIcon/icon/IconX.vue";

@Component({
  components: {
    Icon,
    IconX,
    PDropdown,
    PFormInputText,
  },
})
export default class StepPickup extends Vue {
  @Getter("getCommodities") commodities!: Commodity[];
  @Prop({ required: true }) value!: OrderPickupData;
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  down: string = require("@/orders/assets/icons/icon-chevron-down.svg");
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  currencies: Currency[] = [];
  allCurrencies: Currency[] = [];
  currencyCode = "";
  quantity: string | number = 1;
  comments = "";
  divisasTotales: RequestedCurrencyData[] = [];
  requestedCurrencies: RequestedCurrencyData[] = [];
  isChecked = false;
  addCurrencies = false;

  @Watch("requestedCurrencies", { deep: true })
  onRequestedCurrenciesChange() {
    this.onChangeQuantity();
  }

  onChangeQuantity(): void {
    this.divisasTotales = [];
    const sumasPorDivisa: any = {};
    this.requestedCurrencies.forEach((item) => {
      const code = item.currency.code;
      if (!sumasPorDivisa[code]) {
        sumasPorDivisa[code] = 0;
      }
      sumasPorDivisa[code] += Number(item.quantity);
    });

    Object.keys(sumasPorDivisa).forEach((code) => {
      const currency = this.allCurrencies.find((c) => c.code === code);
      if (currency) {
        this.divisasTotales.push({
          currency: currency,
          quantity: sumasPorDivisa[code],
        });
      }
    });
    this.value.requestedCurrencies = this.requestedCurrencies;
  }

  async created() {
    this.allCurrencies = await this.$services.order.fetchOrderCurrencies(this.notChangeOrder.country.code);
    this.currencies = this.allCurrencies;
    this.requestedCurrencies = this.value.undeclaredAmount ? [] : this.value.requestedCurrencies;
    this.setupCurrencies();
  }

  setupCurrencies() {
    if (!this.value.undeclaredAmount && this.requestedCurrencies.length === 0) {
      this.currencyCode = this.getPrincipalCurrency();
      this.requestedCurrencies = [];
      this.addCurrencies = true;
    } else {
      this.updateAvailableCurrencies();
    }
  }

  getPrincipalCurrency(): string {
    const foundCurrency = this.allCurrencies.find((currency) => currency.principalCurrency === true);
    return foundCurrency ? foundCurrency.code : "";
  }

  onChangeCheckUndeclared(): void {
    this.value.undeclaredAmount = !this.value.undeclaredAmount;
    if (this.value.undeclaredAmount) {
      this.currencyCode = "";
      this.quantity = 1;
      this.requestedCurrencies = [];
    } else {
      this.addCurrencies = false;
      this.setupCurrencies();
      this.updateAvailableCurrencies();
    }
    this.$emit("change", this.value);
  }

  currencyFormat(amount: number) {
    return Utils.currencyFormat(amount);
  }

  get formatCurrencies(): string[] {
    return this.currencies.map((currency) => currency.code);
  }

  get shouldShowCurrenciesButton() {
    return !this.value.undeclaredAmount && this.formatCurrencies.length > 0 && this.requestedCurrencies.length > 0;
  }

  showCurrencies() {
    this.addCurrencies = true;
    this.updateAvailableCurrencies();
  }

  addNewItem() {
    if (this.currencyCode === "Seleccionar" || this.quantity === 0) {
      return;
    }
    const newRequestedCurrencyData: RequestedCurrencyData = {
      currency: this.currencies.find((currency) => currency.code === this.currencyCode) as Currency,
      quantity: this.quantity,
    };
    const exist = this.requestedCurrencies.find(
      (item) => item.currency.code === newRequestedCurrencyData.currency.code
    );
    if (exist) {
      const obj = this.requestedCurrencies[this.requestedCurrencies.indexOf(exist)];
      obj.quantity = Number(newRequestedCurrencyData.quantity) + Number(obj.quantity);
    } else {
      this.requestedCurrencies.push(newRequestedCurrencyData);
    }
    this.updateAvailableCurrencies();
    this.addCurrencies = false;
  }

  onDeleteCurrencies(currencyCode: string) {
    this.requestedCurrencies = this.requestedCurrencies.filter((item) => item.currency.code !== currencyCode);
    const deletedCurrency = this.allCurrencies.find((currency) => currency.code === currencyCode);
    if (deletedCurrency) {
      this.currencies.push(deletedCurrency);
    }
    this.setupCurrencies();
    this.$emit("change", this.value);
  }

  updateAvailableCurrencies() {
    const requestedCurrencyCodes = new Set(this.requestedCurrencies.map((item) => item.currency.code));
    this.currencies = this.allCurrencies.filter((currency) => !requestedCurrencyCodes.has(currency.code));
    if (this.shouldShowCurrenciesButton) {
      this.resetData();
    }
    this.$emit("change", this.value);
  }

  resetData(): void {
    this.currencyCode = "Seleccionar";
    this.quantity = 1;
  }

  validateInputArray(value: any, index: number) {
    if (isNaN(value) || value === "") {
      this.requestedCurrencies[index] = { ...this.requestedCurrencies[index], quantity: "" };
    } else if (value < 0) {
      this.requestedCurrencies[index] = { ...this.requestedCurrencies[index], quantity: Math.abs(value) };
    } else {
      this.requestedCurrencies[index] = { ...this.requestedCurrencies[index], quantity: Number(value) };
    }
    this.$set(this.requestedCurrencies, index, { ...this.requestedCurrencies[index] });
    this.$emit("change", this.value);
  }

  validateInput(quantity: string | number): void {
    if (quantity === "" || isNaN(Number(quantity))) {
      this.quantity = "";
    } else if (Number(quantity) < 0) {
      this.quantity = Math.abs(Number(quantity));
    } else {
      this.quantity = Number(quantity);
    }
  }
}
