
import { Component, Vue } from "vue-property-decorator";
import {PCol, PRow} from "@/common/components";

@Component({
  name: "panel-widgets",
  components: {
    PRow,
    PCol,
  },
})
export class PanelWidgets extends Vue {
  public visible = false;

  constructor() {
    super();
  }
}
export default PanelWidgets;
