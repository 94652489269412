
import { Component, Vue, Prop } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class PTab extends Vue {
  @Prop({ required: false, default: "" }) title!: string;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: number;
}
export default PTab;
