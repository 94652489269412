
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import StepAssistance from "@/orders/components/ModalCreate/FourStep/stepAssistance.vue";
import StepDelivery from "@/orders/components/ModalCreate/FourStep/stepDelivery.vue";
import StepSupply from "@/orders/components/ModalCreate/FourStep/stepSupply.vue";
import StepPickup from "@/orders/components/ModalCreate/FourStep/stepPickup.vue";
import VueOrders from "@/orders/vue-orders";
import {
  OrderAssistanceData,
  CreateOrderAssistanceData,
  CreateOrderDeliveryData,
  CreateOrderPickupData,
  OrderDeliveryData,
  OrderDTO,
  OrderPickupData,
  OrderSupplyData,
  CreateOrderSupplyData,
  CreateOrderDeliveryChangeData,
  OrderDeliveryChangeData,
} from "@/orders/entities";
import StepDeliveryChange from "@/orders/components/ModalCreate/FourStep/stepDeliveryChange.vue";
import { RequestedCurrencyData } from "@/orders/entities/requestedCurrency";

@Component({
  components: {
    StepDeliveryChange,
    StepDelivery,
    StepAssistance,
    StepSupply,
    StepPickup,
    Icon,
  },
  mixins: [VueOrders],
})
export default class StepFour extends Vue {
  @Prop({ required: true }) notChangeOrder!: OrderDTO;

  left: string = require("@/orders/assets/icons/icon-chevron-left.svg");
  pickup = {} as OrderPickupData;
  assistanceFailure = {} as OrderAssistanceData;
  deliveryData = {} as OrderDeliveryData;
  deliveryChangeData = {} as OrderDeliveryChangeData;
  supply = {} as OrderSupplyData;
  comments = "";
  isDisableCompleteStep = false;

  created(): void {
    const commodity = this.notChangeOrder.commodity;
    const order = this.notChangeOrder.orderData;
    const editable = this.notChangeOrder.editable || false;
    this.comments = order?.comments || "";

    switch (commodity.name) {
      case "PICKUP": {
        const pickup = order as CreateOrderPickupData;
        this.pickup = {
          requestedCurrencies: pickup?.requestedCurrencies || [],
          undeclaredAmount: editable ? !pickup?.hasPickupDeclared : pickup?.hasPickupDeclared || false,
        };
        this.isDisableCompleteStep = !pickup?.hasPickupDeclared || pickup?.requestedCurrencies.length > 0;
        break;
      }
      case "ASSISTANCE": {
        const assistance = order as CreateOrderAssistanceData;
        this.assistanceFailure = {
          assistanceFailureId: assistance?.assistanceFailureId || "",
        };
        break;
      }
      case "DELIVERY": {
        const delivery = order as CreateOrderDeliveryData;
        this.deliveryData = {
          requestedDenominationPacks: delivery?.requestedDenominationPacks || [],
          requestedDenominations: delivery?.requestedDenominations || [],
          totalPrice: delivery?.requestedDenominations.reduce((acc, curr) => acc + curr.total, 0) || 0,
        };
        break;
      }
      case "DELIVERY_CHANGE": {
        const deliveryChange = order as CreateOrderDeliveryChangeData;
        this.deliveryChangeData = {
          requestedDenominationPacks: deliveryChange?.requestedDenominationPacks || [],
          requestedDenominations: deliveryChange?.requestedDenominations || [],
          totalPrice: deliveryChange?.requestedDenominations.reduce((acc, curr) => acc + curr.total, 0) || 0,
        };
        break;
      }
      case "SUPPLY": {
        const supply = order as CreateOrderSupplyData;
        this.supply = {
          requestedSupplies: supply?.requestedSupplies || [],
        };
        break;
      }
      default:
        console.error("Commodity not valid");
    }
  }

  // Métodos
  prevPage(): void {
    this.$emit("prevPage", true);
  }

  completeStep(): void {
    const commodity = this.notChangeOrder.commodity;
    if (this.isDisableCompleteStep) return;
    if (commodity) {
      const commodityName = commodity.name;
      if (commodityName === "PICKUP") {
        const body: Partial<CreateOrderPickupData> = {
          requestedCurrencies: this.pickup.requestedCurrencies,
          hasPickupDeclared: !this.pickup.undeclaredAmount,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      } else if (commodityName === "ASSISTANCE") {
        const body: Partial<CreateOrderAssistanceData> = {
          assistanceFailureId: this.assistanceFailure.assistanceFailureId,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      } else if (commodityName === "DELIVERY") {
        const body: Partial<CreateOrderDeliveryData> = {
          requestedDenominationPacks: this.deliveryData.requestedDenominationPacks,
          requestedDenominations: this.deliveryData.requestedDenominations,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      } else if (commodityName === "DELIVERY_CHANGE") {
        const body: Partial<CreateOrderDeliveryChangeData> = {
          requestedDenominationPacks: this.deliveryChangeData.requestedDenominationPacks,
          requestedDenominations: this.deliveryChangeData.requestedDenominations,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      } else if (commodityName === "SUPPLY") {
        const body: Partial<CreateOrderSupplyData> = {
          requestedSupplies: this.supply.requestedSupplies,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      }
    }
  }

  isInvalidQuantity(quantity: number | string): boolean {
    return quantity === 0 || quantity === "" || quantity == null;
  }

  changeOrderData(data: any): void {
    const commodity = this.notChangeOrder.commodity;
    this.$nextTick(() => {
      switch (commodity.name) {
        case "PICKUP": {
          const hasUndeclaredAmount = !data.undeclaredAmount;
          const hasInvalidCurrencies =
            data.requestedCurrencies.length === 0 ||
            data.requestedCurrencies.some((item: RequestedCurrencyData) => this.isInvalidQuantity(item.quantity));
          this.isDisableCompleteStep = hasUndeclaredAmount && hasInvalidCurrencies;
          break;
        }
        case "SUPPLY": {
          this.isDisableCompleteStep = data.requestedSupplies.length === 0;
          break;
        }
        default:
          this.isDisableCompleteStep = false;
      }
    });
  }
}
