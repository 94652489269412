
import { Component, Vue, Prop } from "vue-property-decorator";
import { LogicCashTodayBalance } from "@/entities";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export default class LogicCashTodayDetailHeader extends Vue {
  @Prop() public logicCashTodayBalance!: LogicCashTodayBalance;
}
