
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { FilterAvancedSearchTransactions } from "./transactionsPage.type";
import {
  PFormInputHour,
  PFormDatepickerActions,
  PFormCheckBox,
  PButton,
  PFormInputText,
  PRow,
  PCol,
} from "@/common/components";
import { LogicCashToday } from "@/entities";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";

@Component({
  mixins: [DateTimeMixin],
  components: {
    PRow,
    PCol,
    PFormInputHour,
    PFormDatepickerActions,
    PFormCheckBox,
    PButton,
    PFormInputText,
  },
})
export class FilterAvancedSearchTransactionsPage extends Vue {
  @Prop({ required: true }) value!: FilterAvancedSearchTransactions;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value.logicCashTodays) {
      this.valueLocal.logicCashTodays = JSON.parse(JSON.stringify(this.value.logicCashTodays));
    }
    this.valueLocal.userTransaction = this.value.userTransaction;
    this.valueLocal.entityName = this.value.entityName;
    this.valueLocal.entityNameAccrediting = this.value.entityNameAccrediting;
    this.valueLocal.isCheckAccreditation = this.value.isCheckAccreditation;
    this.valueLocal.creditDateStart = this.value.creditDateStart;
    this.valueLocal.creditDateEnd = this.value.creditDateEnd;
    this.valueLocal.show = this.value.show;
    if (this.value.creditDateStart) this.creditDateTimeStart = this.getTimeOfDate(this.value.creditDateStart);
    if (this.value.creditDateEnd) this.creditDateTimeEnd = this.getTimeOfDate(this.value.creditDateEnd);
  }
  @Prop({ required: true }) logicCashTodays!: LogicCashToday[] | undefined;

  language = i18n.locale;
  valueLocal: FilterAvancedSearchTransactions = {
    logicCashTodays: [],
    userTransaction: "",
    entityName: "",
    entityNameAccrediting: "",
    isCheckAccreditation: false,
    creditDateStart: null,
    creditDateEnd: null,
    show: false,
  };
  maxDays = 31;
  creditDateTimeStart = "00:00";
  creditDateTimeEnd = "23:59";

  get endSelectorCreditDateEnd(): Date | undefined {
    if (this.valueLocal.creditDateStart) {
      const maxDate = this.addDaysToDate(this.valueLocal.creditDateStart, this.maxDays);
      if (
        this.valueLocal.creditDateEnd &&
        this.intervalExceedNumberOfDays(this.valueLocal.creditDateStart, this.valueLocal.creditDateEnd, this.maxDays)
      ) {
        this.valueLocal.creditDateEnd = new Date(maxDate.getTime());
      }
      return new Date(maxDate.getTime());
    }
    return undefined;
  }
  get anyIsEarlyValue(): boolean {
    let returnTemp = false;
    // chequeo entre las logica cash today seleccionadas
    this.valueLocal.logicCashTodays.forEach((lo) => {
      if (this.logicCashTodays?.find((lct) => lo == lct.id && lct.isEarlyValue)) {
        returnTemp = true;
      }
    });
    return returnTemp;
  }
  cancelAvancedSearch(): void {
    this.valueLocal.entityName = "";
    this.valueLocal.userTransaction = "";
    this.valueLocal.isCheckAccreditation = false;
    this.valueLocal.creditDateStart = null;
    this.valueLocal.creditDateEnd = null;
    this.resetTime();
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }
  applyAvancedSearch(): void {
    this.valueLocal.show = false;
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }
  resetTime(): void {
    this.setTime("00:00", "23:59");
  }
  isIntervalDateValid(): boolean {
    return this.isIntervalValid(this.valueLocal.creditDateStart, this.valueLocal.creditDateEnd);
  }
  getTimeOfDate(date: Date): string {
    return this.getHoursOfDate(date) + ":" + this.getMinutesOfDate(date);
  }
  getHoursOfDate(date: Date): string {
    return ("0" + date.getHours()).slice(-2);
  }
  getMinutesOfDate(date: Date): string {
    return ("0" + date.getMinutes()).slice(-2);
  }
  changeTimeStart(newTimeStart: string): void {
    this.setTime(newTimeStart, this.creditDateTimeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeEnd(newTimeStart);
    }
  }
  changeTimeEnd(newTimeEnd: string): void {
    this.setTime(this.creditDateTimeStart, newTimeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeStart(newTimeEnd);
    }
  }
  changeDateStart(): void {
    this.setTime(this.creditDateTimeStart, this.creditDateTimeEnd);
    if (this.valueLocal.creditDateStart && !this.isIntervalDateValid()) {
      this.valueLocal.creditDateEnd = new Date(this.valueLocal.creditDateStart.getTime());
      this.resetTime();
    }
  }
  changeDateEnd(): void {
    this.setTime(this.creditDateTimeStart, this.creditDateTimeEnd);
    if (this.valueLocal.creditDateEnd && !this.isIntervalDateValid()) {
      this.valueLocal.creditDateStart = new Date(this.valueLocal.creditDateEnd.getTime());
      this.resetTime();
    }
  }
  setTimeFromString(time: string, date: Date, isForEnd = false): void {
    if (time && date) {
      const timeLocal = time.split(":");
      date.setHours(parseInt(timeLocal[0]));
      date.setMinutes(parseInt(timeLocal[1]));
      date.setSeconds(isForEnd ? 59 : 0);
      date.setMilliseconds(isForEnd ? 999 : 1);
    }
  }
  completeTwoDigitsWithZero(value: string): string {
    const timeLocal = value.split(":");
    return ("0" + timeLocal[0]).slice(-2) + ":" + ("0" + timeLocal[1]).slice(-2);
  }
  setTime(timeStart: string, timeEnd: string): void {
    if (this.valueLocal.creditDateStart) this.setTimeFromString(timeStart, this.valueLocal.creditDateStart);
    this.creditDateTimeStart = this.completeTwoDigitsWithZero(timeStart);
    if (this.valueLocal.creditDateEnd) this.setTimeFromString(timeEnd, this.valueLocal.creditDateEnd, true);
    this.creditDateTimeEnd = this.completeTwoDigitsWithZero(timeEnd);
  }
  changeCreditDateHourStart(time: string): void {
    if (this.valueLocal.creditDateStart) this.setTimeFromString(time, this.valueLocal.creditDateStart);
  }
  changeCreditDateHourEnd(time: string): void {
    if (this.valueLocal.creditDateEnd) this.setTimeFromString(time, this.valueLocal.creditDateEnd, true);
  }
  creditDateTodayDate(): void {
    this.valueLocal.creditDateStart = this.getTodayDate();
    this.valueLocal.creditDateEnd = this.getTodayDate();
    this.resetTime();
  }
  creditDateYesterdayDate(): void {
    this.valueLocal.creditDateStart = this.getYesterdayDate();
    this.valueLocal.creditDateEnd = this.getYesterdayDate();
    this.resetTime();
  }
  creditDateThisWeekDate(): void {
    const dates = this.getThisWeekDates();
    this.valueLocal.creditDateStart = dates.start;
    this.valueLocal.creditDateEnd = dates.end;
    this.resetTime();
  }
  creditDateThisMonthDate(): void {
    const dates = this.getThisMonthDates();
    this.valueLocal.creditDateStart = dates.start;
    this.valueLocal.creditDateEnd = dates.end;
    this.resetTime();
  }
}
export default FilterAvancedSearchTransactionsPage;
