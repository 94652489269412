
import { Modal, SessionExpiredModal, PLoading, PCol, PRow } from "@/common/components";
import { User } from "@/entities";
import AppIdle from "@app-idle/core";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { EventBus } from "./event-bus";

@Component({
  name: "app",
  components: {
    SessionExpiredModal,
    //Loading,
    Modal,
    PLoading,
    PRow,
    PCol,
  },
})
export default class App extends Vue {
  //TODO añadir cargador nuestro
  @Getter("getIsSignedIn") getIsSignedIn!: boolean;
  @Getter("getLoggedUser") getLoggedUser!: User;
  @Action("setLocale") setLocale!: (locale: string) => void;
  @Action("fetchStaticData") fetchStaticData!: () => Promise<void>;

  accepted: boolean = localStorage.getItem("acceptCookies") === "true";
  loadings = 0;
  //TODO Montar lo de la sesion con modal nuestro
  idle: AppIdle = new AppIdle({
    timeout: 1800000,
    onIdle: () => this.$bvModal.show("sessionExpiredModal"),
    onCancel: () => this.$bvModal.hide("sessionExpiredModal"),
    events: ["mousemove", "keydown", "mousedown", "touchstart", "wheel"], // events that cancel the idle state
    multi: true, // sets whether the idle state can be cancelled from multiple tabs/windows in one app.
    storageKey: "AppIdle", // localStorage key used for notifying other tabs/windows
    eventTarget: window, //the DOM target to attach event listeners that cancel the idle state
  });

  constructor() {
    super();
  }

  get isLoading(): boolean {
    return this.loadings > 0;
  }

  async created(): Promise<void> {
    this.setLocale(this.$i18n.locale);

    Vue.$axios.interceptors.request.use(
      (config) => {
        this.loadings++;
        return config;
      },
      (error) => {
        this.loadings--;
        return Promise.reject(error);
      }
    );

    Vue.$axios.interceptors.response.use(
      (response) => {
        this.loadings--;
        return response;
      },
      (error) => {
        this.loadings--;
        return Promise.reject(error);
      }
    );
  }

  async mounted(): Promise<void> {
    EventBus.$on("language-change", (value: string) => {
      {
        this.$i18n.locale = value;
        this.setLocale(value);
        document.cookie = `LOCALE=${value}; path=/`;
        const html = document.documentElement;
        html.setAttribute("lang", value);
      }
    });

    // TODO cargacamos datos estaticos esto se deberia hacer al hacer login
    await this.fetchStaticData();
  }

  acceptCookies(): void {
    localStorage.setItem("acceptCookies", "true");
    this.accepted = true;
  }
}
