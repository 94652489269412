import { render, staticRenderFns } from "./stepThree.vue?vue&type=template&id=6b1ae4b8&scoped=true&"
import script from "./stepThree.vue?vue&type=script&lang=ts&"
export * from "./stepThree.vue?vue&type=script&lang=ts&"
import style0 from "./stepThree.vue?vue&type=style&index=0&id=6b1ae4b8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1ae4b8",
  null
  
)

export default component.exports