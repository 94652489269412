
import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import Utils from "@/orders/components/Commons/utils";
import { Getter } from "vuex-class";
import VueOrders from "@/orders/vue-orders";
import { Commodity, CreateOrderDeliveryData, CreateOrderPickupData, Order, OrderDTO } from "@/orders";
import DateTimeMixin from "@/mixins/datetime";

@Component({
  components: {
    Icon,
  },
  mixins: [VueOrders, DateTimeMixin],
})
export default class StepFive extends Vue {
  private readonly pattern = `EEEE, d MMMM`;
  // Props
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) dateSelected!: Date;
  @Prop({ required: true }) baseOrder!: OrderDTO;
  @Prop() sendOrders!: OrderDTO[];
  @Prop() order: Order | undefined;

  // Data
  @Getter("getCommoditiesService") commodities!: Commodity[];
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  edit: string = require("@/orders/assets/icons/icon-icn-edit-3.svg");
  remove: string = require("@/orders/assets/icons/icon-x.svg");

  // Methods
  editStep(order: OrderDTO): void {
    this.$emit("editStep", {
      ...order,
      editable: true,
    });
  }

  removeOrder(order: OrderDTO): void {
    this.$emit("removeOrder", order);
  }

  submitOrders(): void {
    this.$emit("submitOrders", this.sendOrders);
  }

  currencyFormat(amount: number): string {
    return Utils.currencyFormat(amount);
  }

  formatDate(fecha: Date): string {
    const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "short", year: "numeric" };
    const formatter = new Intl.DateTimeFormat("es-ES", options);
    return formatter.format(fecha);
  }

  closeModal(): void {
    this.$emit("closeModal");
  }

  getAmountDenominations(item: OrderDTO) {
    const order = item as OrderDTO;
    const createOrder = order.orderData as CreateOrderDeliveryData;
    const combinedDenominations = [...createOrder.requestedDenominations, ...createOrder.requestedDenominationPacks];
    return combinedDenominations.reduce((acc, item) => {
      let code: string;

      if ("denomination" in item) {
        code = item.denomination.currency.code;
      } else if ("denominationPack" in item) {
        code = item.denominationPack.currency.code;
      } else {
        return acc;
      }
      if (!acc[code]) {
        acc[code] = { total: 0, currencyCode: code };
      }
      acc[code].total += item.total;
      return acc;
    }, {} as Record<string, { total: number; currencyCode: string }>);
  }

  getRequestedCurrencies(item: OrderDTO) {
    const data = item as OrderDTO;
    const obj = data.orderData as CreateOrderPickupData;
    if (obj.requestedCurrencies.length === 0) {
      return "- $";
    }
    return obj.requestedCurrencies.map((currencyItem) => ({
      total: currencyItem.quantity,
      currencyCode: currencyItem.currency.code,
    }));
  }

  isCommodityWithAmount(order: OrderDTO) {
    const validCommodities = ["DELIVERY", "DELIVERY_CHANGE", "PICKUP"];
    return validCommodities.includes(order.commodity.name);
  }

  showAmount(item: OrderDTO): string | Array<any> {
    switch (item.commodity.name) {
      case "DELIVERY":
      case "DELIVERY_CHANGE": {
        const denominations = this.getAmountDenominations(item);
        return Object.values(denominations);
      }
      case "PICKUP": {
        return this.getRequestedCurrencies(item);
      }
      default:
        return "- $";
    }
  }

  showCommodities(commodities: Commodity[], orders: OrderDTO[]): Commodity[] {
    if (orders[0].commodity.name === "ASSISTANCE") {
      return [];
    }
    return commodities.filter((commodity) => {
      return !orders.some((order) => order.commodity.name === commodity.name || commodity.name === "ASSISTANCE");
    });
  }

  addOrder(commodity: Commodity): void {
    this.$emit("addOrder", commodity);
  }

  get formattedDateSelected(): string {
    return this.formatDateTime(this.dateSelected, this.pattern)!;
  }
}
