
import { Component, Vue, Prop } from "vue-property-decorator";
import { ServicePointBalance, ServicePointBalanceBalance } from "@/entities";
import { PMiniLabel, Panel, PPiePercentage } from "@/common/components";
import { LocalDateTime } from "@js-joda/core";
import DateTimeMixin from "@/mixins/datetime";

@Component({
  mixins: [DateTimeMixin],
  components: {
    PMiniLabel,
    Panel,
    PPiePercentage,
  },
})
export default class ServicePointDetailBalance extends Vue {
  @Prop() public servicePointBalance!: ServicePointBalance;

  miniLabelFontSize = "12px";
  currencies = this.servicePointBalance.balances.map((balance) => balance.currency);
  numberOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    useGrouping: true,
  };

  getBalanceByCurrency(currency: string): ServicePointBalanceBalance | undefined {
    return this.servicePointBalance.balances.find((balance) => balance.currency == currency);
  }

  get info(): string {
    if (this.servicePointBalance && this.servicePointBalance.lastTransactionDate) {
      return this.formatDateTimeTodayYerterday(this.servicePointBalance.lastTransactionDate as LocalDateTime) || "";
    }
    return "";
  }
}
