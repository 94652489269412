
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  PFormInputHour,
  PFormSelectNarrow,
  PFormDatepickerActions,
  PFormCheckBox,
  PFormSelect,
  PFormSelectMultiFilter,
  PButton,
  PSidebar,
  PFormInputText,
  ButtonSearch,
  PRow,
} from "@/common/components";
import { FilterTransations, FilterAvancedSearchTransactions } from "./transactionsPage.type";
import FilterAvancedSearchTransactionsPage from "./FilterAvancedSearchTransactionsPage.vue";
import { ETransactionTypeList, LogicCashToday, ServicePoint, User } from "@/entities";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";
import { getUniqueObjectListBykey } from "@/common/utils";
import { Getter } from "vuex-class";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  mixins: [DateTimeMixin],
  name: "filter-transactions-page",
  components: {
    PRow,
    PIcon,
    PFormSelectNarrow,
    PFormDatepickerActions,
    PFormCheckBox,
    PFormSelect,
    PFormSelectMultiFilter,
    PButton,
    PSidebar,
    PFormInputText,
    PFormInputHour,
    FilterAvancedSearchTransactionsPage,
    ButtonSearch,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class FilterTransactionsPage extends Vue {
  @Prop({ required: false, default: undefined }) logicCashTodays!: LogicCashToday[]; // required?
  @Prop({ required: false }) logicCashTodayId!: string;
  @Prop({ required: false, default: undefined }) servicePoints!: ServicePoint[]; // required?
  @Prop({ required: false }) servicePointId!: string;

  @Getter("getLoggedUser") loggedUser?: User;
  @Watch("loggedUser", { immediate: true, deep: true })
  onChangeSearchValue(): void {
    this.maxDaysToSearch = this.shouldShowMonthOption() ? 31 : 7;
  }

  language = i18n.locale;
  isActiveButtonAvancedSearch = false;
  showModalAvancedSearch = false;
  valueAvancedSearch: FilterAvancedSearchTransactions = {
    logicCashTodays: [],
    userTransaction: "",
    entityName: "",
    entityNameAccrediting: "",
    isCheckAccreditation: false,
    creditDateStart: null,
    creditDateEnd: null,
    show: false,
  };
  transactionTypes = ETransactionTypeList;
  fromTransactions = true;
  valueLocal: FilterTransations = {
    dateStart: new Date(),
    dateEnd: new Date(),
    transactionType: [
      this.transactionTypes.CASHIN,
      this.transactionTypes.SHIPOUT,
      "",
      this.transactionTypes.SHIPIN,
      this.transactionTypes.CASHOUT,
      "",
    ],
    entityName: "",
    userTransaction: "",
    creditDateStart: null,
    creditDateEnd: null,
    isCheckAccreditation: false,
    entityNameAccrediting: "",
    smartDevices: [],
  };
  /** @TODO esto hay que recorgelo del back y meterlo en store */
  selectItemCenterFilter = "";

  servicePointsFilterProperties = [
    { text: this.$t("transactions.filter.nameDevice"), value: "text" },
    //{ text: this.$t("transactions.filter.nameServicePoint"), value: "text" }
  ];
  logicCashTodaysFilterProperties = [
    { text: this.$t("transactions.filter.nameLogicCashToday"), value: "text" },
    // { text: this.$t("transactions.filter.deviceId"), value: "id" },
  ];
  options = [
    { text: this.$t("transactions.filter.searchByTransactionDate"), value: false },
    // { text: this.$t("transactions.filter.searchByAcreditationDate"), value: true },
  ];
  centerFilterTranslation = { textSelectAnOption: this.$t("transactions.filter.filterByEstablishment") };
  filtrosCheck = {
    Deposito: "",
    Regogida: "",
    Balance: "",
    Cargas: "",
    Retiradas: "",
    Conteo: "",
  };

  monthsToSearchBack = 6;
  minDateStart = this.subtractMonths(new Date(), this.monthsToSearchBack);
  maxDaysToSearch = 31;
  timeStart = "00:00";
  timeEnd = "23:59";

  created(): void {
    this.servicePoints;
    if (this.logicCashTodayId) {
      this.valueLocal.smartDevices.push(this.logicCashTodayId);
    }
    if (this.servicePointId) {
      this.valueLocal.smartDevices.push(this.servicePointId);
    }
    this.getModelType;
    this.resetTime();
  }

  isSearchEnabled(): boolean {
    return this.anyTransactionType && this.anySmartDevice;
  }

  shouldShowMonthOption(): boolean {
    return !this.loggedUser?.isProsegur();
  }

  get getFilterProperties(): any[] {
    if (this.logicCashTodayId) return this.logicCashTodaysFilterProperties;
    else return this.servicePointsFilterProperties;
  }

  get getModelType(): string[] {
    return this.valueLocal.smartDevices;
  }
  get anyTransactionType() {
    return this.valueLocal.transactionType.find((sp) => sp != "") ? true : false;
  }
  get anySmartDevice() {
    return this.valueLocal.smartDevices.find((sp) => sp != "") ? true : false;
  }
  get getInvalidFeedback(): string {
    if (this.logicCashTodayId) return "transactions.filter.invalidLogicCashTodays";
    else return "transactions.filter.invalidServicePoints";
  }

  get optionsCenterFilter(): any[] {
    let options = this.getLogicCashTodaysOptions;
    options = options.concat(this.getServicePointsOptions);
    return getUniqueObjectListBykey(options, "value");
  }

  get getServicePointsOptions(): any[] {
    const returnTemp: any[] = [];
    this.servicePoints?.forEach((lo) => {
      if (!returnTemp.find((re) => re.value === lo.center.code)) {
        returnTemp.push({ text: lo.center.name, value: lo.center.code });
      }
    });
    return returnTemp;
  }

  get getLogicCashTodaysOptions(): any[] {
    const returnTemp: any[] = [];
    this.logicCashTodays?.forEach((lo) => {
      if (!returnTemp.find((re) => re.value === lo.center.code)) {
        returnTemp.push({ text: lo.center.name, value: lo.center.code });
      }
    });
    return returnTemp;
  }

  get servicePointOrLogicCashToday(): any[] {
    const servicePoints: any[] = this.servicePointComp;
    const logicCash: any[] = this.logicCashTodayComp;
    const smartDevices: any[] = servicePoints.concat(logicCash);
    return smartDevices;
  }
  get servicePointComp(): any[] {
    let returnTemp: any[] = [];
    this.servicePoints?.forEach((sp) => {
      returnTemp.push({ text: sp.name, value: sp.id, centerName: sp.center.code });
    });
    const optionsTempFilter = returnTemp.filter(
      (sp) => this.selectItemCenterFilter && sp.centerName == this.selectItemCenterFilter
    );
    if (this.selectItemCenterFilter || optionsTempFilter.length > 0) {
      returnTemp = optionsTempFilter;
    }
    return returnTemp;
  }
  get logicCashTodayComp(): any[] {
    let returnTemp: any[] = [];
    this.logicCashTodays?.forEach((lct) => {
      returnTemp.push({ text: lct.name, value: lct.id, centerName: lct.center.code });
    });
    const optionsTempFilter = returnTemp.filter((lo) => lo.centerName == this.selectItemCenterFilter);
    if (this.selectItemCenterFilter || optionsTempFilter.length > 0) {
      returnTemp = optionsTempFilter;
    }
    return returnTemp;
  }
  get textButtonAvancedSearch(): string {
    let numFilter = 0;
    if (this.valueLocal.entityName != "") {
      numFilter++;
    }
    if (this.valueLocal.userTransaction != "") {
      numFilter++;
    }
    if (this.valueLocal.isCheckAccreditation) {
      numFilter++;
    }
    if (this.valueLocal.entityNameAccrediting != "") {
      numFilter++;
    }
    if (this.valueLocal.creditDateStart) {
      numFilter++;
    }
    if (this.valueLocal.creditDateEnd) {
      numFilter++;
    }
    if (numFilter > 0) {
      this.isActiveButtonAvancedSearch = true;
      return (
        "<span class='textButtonFiltersModalAvancedSearch'>" +
        this.$t("transactions.filter.avancedSearch") +
        " " +
        "(" +
        numFilter +
        ") </span>"
      );
    } else {
      this.isActiveButtonAvancedSearch = false;
      return this.$t("transactions.filter.avancedSearch") + " " + "(" + numFilter + ")";
    }
  }
  get selectItemCenterFilterText(): string {
    let returnTemp = "";
    const optionCenterFilter = this.optionsCenterFilter.find((op) => op.value == this.selectItemCenterFilter);
    if (optionCenterFilter) {
      returnTemp = optionCenterFilter.text;
    }
    return returnTemp;
  }
  get endSelectorDateEnd(): Date {
    const maxDate = this.addDaysToDate(this.valueLocal.dateStart, this.maxDaysToSearch);
    if (this.intervalExceedNumberOfDays(this.valueLocal.dateStart, this.valueLocal.dateEnd, this.maxDaysToSearch)) {
      this.valueLocal.dateEnd = new Date(maxDate.getTime());
      this.setTime(this.timeStart, this.timeEnd);
    }
    return new Date(maxDate.getTime());
  }

  changeAvancedSearch(filter: FilterAvancedSearchTransactions): void {
    this.valueLocal.userTransaction = filter.userTransaction;
    this.valueLocal.entityName = filter.entityName;
    this.valueLocal.entityNameAccrediting = filter.entityNameAccrediting;
    this.valueLocal.isCheckAccreditation = filter.isCheckAccreditation;
    this.valueLocal.creditDateStart = filter.creditDateStart;
    this.valueLocal.creditDateEnd = filter.creditDateEnd;
    this.showModalAvancedSearch = filter.show;
  }
  showModalAvancedSearchClick(): void {
    this.valueAvancedSearch = Object.assign(this.valueLocal);
    this.valueAvancedSearch.show = true;
    this.showModalAvancedSearch = true;
  }
  closeSelectedPoint(): void {
    this.selectItemCenterFilter = "";
  }
  search(): void {
    if (this.anySmartDevice) {
      this.$emit("search", this.valueLocal);
    }
  }
  isIntervalDateValid(): boolean {
    return this.isIntervalValid(this.valueLocal.dateStart, this.valueLocal.dateEnd);
  }
  resetTime(): void {
    this.setTime("00:00", "23:59");
  }
  changeDateStart(): void {
    this.setTime(this.timeStart, this.timeEnd);
    if (!this.isIntervalDateValid()) {
      this.valueLocal.dateEnd = new Date(this.valueLocal.dateStart.getTime());
      this.resetTime();
    }
  }
  changeDateEnd(): void {
    this.setTime(this.timeStart, this.timeEnd);
    if (!this.isIntervalDateValid()) {
      this.valueLocal.dateStart = new Date(this.valueLocal.dateEnd.getTime());
      this.resetTime();
    }
  }
  changeTimeStart(newTimeStart: string): void {
    this.setTime(newTimeStart, this.timeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeEnd(newTimeStart);
    }
  }
  changeTimeEnd(newTimeEnd: string): void {
    this.setTime(this.timeStart, newTimeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeStart(newTimeEnd);
    }
  }
  setTimeFromString(time: string, date: Date, isForEnd = false): void {
    if (time && date) {
      const timeLocal = time.split(":");
      date.setHours(parseInt(timeLocal[0]));
      date.setMinutes(parseInt(timeLocal[1]));
      date.setSeconds(isForEnd ? 59 : 0);
    }
  }
  completeTwoDigitsWithZero(value: string): string {
    const timeLocal = value.split(":");
    return ("0" + timeLocal[0]).slice(-2) + ":" + ("0" + timeLocal[1]).slice(-2);
  }
  setTime(timeStart: string, timeEnd: string): void {
    if (this.valueLocal.dateStart) this.setTimeFromString(timeStart, this.valueLocal.dateStart);
    this.timeStart = this.completeTwoDigitsWithZero(timeStart);
    if (this.valueLocal.dateEnd) this.setTimeFromString(timeEnd, this.valueLocal.dateEnd, true);
    this.timeEnd = this.completeTwoDigitsWithZero(timeEnd);
  }
  setTodayDate(): void {
    this.valueLocal.dateStart = this.getTodayDate();
    this.valueLocal.dateEnd = this.getTodayDate();
    this.resetTime();
  }
  setYesterdayDate(): void {
    this.valueLocal.dateStart = this.getYesterdayDate();
    this.valueLocal.dateEnd = this.getYesterdayDate();
    this.resetTime();
  }
  setThisWeekDate(): void {
    const dates = this.getThisWeekDates();
    this.valueLocal.dateStart = dates.start;
    this.valueLocal.dateEnd = dates.end;
    this.resetTime();
  }
  setThisMonthDate(): void {
    const dates = this.getThisMonthDates();
    this.valueLocal.dateStart = dates.start;
    this.valueLocal.dateEnd = dates.end;
    this.resetTime();
  }
}
