import {
  mockCountryService,
  mockDeviceDetailsService,
  mockEstablishmentService,
  mockI18nService,
  mockLogicCashTodayBalanceService,
  mockLogicCashTodayService,
  mockLogicCashTodayTransactionsService,
  mockServicePointBalanceService,
  mockServicePointService,
  mockServicePointTransactionsService,
  mockTransactionService,
  mockUserPreferencesService,
  mockUserService,
} from "@/services";
import { IProviderMock } from "./provider.types";
import { mockOrderService, mockServicePointOrdersService } from "@/orders/services";
import { mockStatusService } from "@/orders/services/status";
import { mockCommoditiesService } from "@/orders/services/commodities";
import { mockAssistanceFailureService } from "@/orders/services/assistanceFailure";
import { mockRatingTypesService } from "@/orders/services/ratingTypes";
import { mockCrewService } from "@/orders/services/crew";
import { mockRatingService } from "@/orders/services/rating";

export const mockProvider = (): IProviderMock => ({
  assistanceFailure: mockAssistanceFailureService(),
  i18n: mockI18nService(),
  user: mockUserService(),
  country: mockCountryService(),
  servicePointBalance: mockServicePointBalanceService(),
  logicCashTodayBalance: mockLogicCashTodayBalanceService(),
  logicCashTodayTransactions: mockLogicCashTodayTransactionsService(),
  establishment: mockEstablishmentService(),
  deviceDetails: mockDeviceDetailsService(),
  logicCashTodays: mockLogicCashTodayService(),
  transactions: mockTransactionService(),
  servicePointTransactions: mockServicePointTransactionsService(),
  servicePoints: mockServicePointService(),
  userPreferences: mockUserPreferencesService(),
  order: mockOrderService(),
  servicePointOrders: mockServicePointOrdersService(),
  status: mockStatusService(),
  commodities: mockCommoditiesService(),
  ratingTypes: mockRatingTypesService(),
  crew: mockCrewService(),
  rating: mockRatingService(),
});
