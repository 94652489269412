import { Country, User, IStaticData } from "@/entities";
import { IGetters } from "@/store";
import { IState } from "../store.types";
import { FieldPTable } from "@/common/components";
import { Order } from "@/orders/entities/order";
import { ServicePoint } from "@/orders/entities/servicePoint";
import { Center, Commodity, Entity, OrderStatus } from "@/orders";

export const getters: IGetters = {
  // Root
  getLocale(state: IState): string {
    return state.locale;
  },

  getUrl(state: IState): string {
    return state.url;
  },

  getIsSignedIn(state: IState): boolean {
    return state.isSignedIn;
  },

  // User
  getLoggedUser(state: IState): User | undefined {
    if (!state.loggedUser) {
      return;
    }
    return new User(state.loggedUser);
  },
  getStaticData(state: IState): IStaticData | undefined {
    //aqui se meteran todos los datos que se usan en la aplicacion pero no se modifican
    return state.staticData;
  },

  // Country
  getCountries(state: IState): Country[] {
    return state.countries.map((data) => new Country(data));
  },

  // Transaction Fields
  getTransactionFields(state: IState): FieldPTable[] {
    let fields: FieldPTable[] = [];
    if (state.staticData && state.staticData.transactionsFieldPTable) {
      fields = state.staticData.transactionsFieldPTable;
    }

    if (state.loggedUser) {
      const user = new User(state.loggedUser);
      const countriesToShowSystemEntryDate = ["BR"];
      if (!countriesToShowSystemEntryDate.includes(user.countryCode)) {
        fields = fields.filter((field) => field.key != "systemEntryDate");
      }
    }
    return fields;
  },
  getTransactionEarlyValueFields(state: IState): FieldPTable[] {
    let fields: FieldPTable[] = [];
    if (state.staticData && state.staticData.transactionsEarlyValueFieldPTable) {
      fields = state.staticData.transactionsEarlyValueFieldPTable;
    }
    return fields;
  },
  getTransactionFieldGroups(state: IState): string[] {
    if (state.staticData && state.staticData.transactionsFieldGroups) {
      return state.staticData.transactionsFieldGroups;
    }
    return [];
  },

  getOrders(state: IState): Order[] {
    return state.orders;
  },

  getOrdersSupplies(state: IState): Order[] {
    return state.ordersSupplies;
  },
  getAssistanceFailures(state: IState): any[] {
    return state.assistanceFailures;
  },
  getServicePointsOrders(state: IState): ServicePoint[] {
    return state.servicePointsOrders;
  },
  getCashTodayServicePointsOrders(state: IState): ServicePoint[] {
    return state.cashTodayServicePointsOrders;
  },
  getCenters(state: IState): Center[] {
    return state.centers;
  },
  getEntities(state: IState): Entity[] {
    return state.entities;
  },
  getStatuses(state: IState): OrderStatus[] {
    return state.statuses;
  },
  getCommodities(state: IState): Commodity[] {
    return state.commodities;
  },
  getCommoditiesService(state: IState): Commodity[] {
    return state.commoditiesService;
  },
  getCountriesOrders(state: IState): any[] {
    return state.countriesOrders;
  },
};
