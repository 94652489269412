
import { Component, Vue, Prop } from "vue-property-decorator";
import { SelectType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PFormSelectTranslations, translations } from "./pFormSelectLanguages";

@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PFormSelectMulti extends Vue {
  @Prop({ required: true }) value!: string[];
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false }) translation!: PFormSelectTranslations;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;

  valueTemp: string[] = [];
  pussOption = false;
  translator: PFormSelectTranslations = translations[0].translations;

  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }
  mounted(): void {
    this.fillValueTempInitial();
  }

  get selectedTexts(): string[] {
    if (this.valueTemp.length > 0) {
      return this.valueTemp;
    } else {
      return [];
    }
  }
  get localPlaceHolder(): string {
    return !this.placeholder && this.translator?.textSelectAnOption
      ? this.translator?.textSelectAnOption
      : this.placeholder;
  }

  giveMeTextOption(slectedText: string): string {
    const optionSelected = this.options.find((op) => op.value === slectedText);
    if (optionSelected) {
      return optionSelected.text;
    }
    return slectedText;
  }
  fillValueTempInitial(): void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.$nextTick(() => {});
    this.options.forEach((op, index) => {
      if (this.value.findIndex((val) => val === op.value) > -1) {
        this.$set(this.valueTemp, index, op.value); // it is because otherwise the value is not reactive
      }
    });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selectItem(itemValue: any): void {
    if (!this.valueTemp.find((vt) => vt === itemValue)) {
      this.valueTemp.push(itemValue);
    }
    this.$emit("input", this.valueTemp);
    this.$emit("change", this.valueTemp);
    this.pussOption = false;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  unSelectedItem(itemValue: any): void {
    this.valueTemp.splice(this.valueTemp.indexOf(itemValue), 1);
    this.$emit("input", this.valueTemp);
    this.$emit("change", this.valueTemp);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  giveMeClass(itemValue: any): string {
    if (this.valueTemp.find((vt) => vt === itemValue)) {
      return "itemSelectSelected_PFormSelectMulti";
    } else {
      return "itemSelect_PFormSelectMulti";
    }
  }
}
export default PFormSelectMulti;
