
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order } from "@/orders";
import VueOrders from "@/orders/vue-orders";
@Component({
  components: {},
  mixins: [VueOrders],
})
export class CardDetail extends Vue {
  @Prop({ required: true }) order!: Order;

  getTotalDenominations(order: Order) {
    const combinedCurrencies = [
      ...order.requestedDenominationPacks.map((item) => ({
        total: item.total,
        code: item.denominationPack.currency.code,
        symbol: item.denominationPack.currency.symbol,
      })),
      ...order.requestedDenominations.map((item) => ({
        total: item.total,
        code: item.denomination.currency.code,
        symbol: item.denomination.currency.symbol,
      })),
    ];
    return combinedCurrencies.reduce((acc, { total, code, symbol }) => {
      const currency = acc.find((curr) => curr.code === code);
      if (currency) {
        currency.total += total;
      } else {
        acc.push({ total, code, symbol });
      }
      return acc;
    }, [] as { total: number; code: string; symbol: string }[]);
  }

  getRequestedCurrencies(order: Order) {
    return order.requestedCurrencies.map((currencyItem) => ({
      total: currencyItem.total,
      code: currencyItem.currency.code,
      symbol: currencyItem.currency.symbol,
    }));
  }

  openModalTripulation(): void {
    this.$emit("openTripulation", this.order);
  }

  openModalDetail(): void {
    this.$emit("openModalDetail", this.order);
  }

  dateFromString(dateString: string): Date {
    const index = dateString.indexOf("[");
    if (index >= 0) return new Date(dateString.slice(0, index));
    return new Date(dateString);
  }

  dateFormated(fecha: string): string {
    const date = this.dateFromString(fecha);
    const month = date.toLocaleDateString("es-ES", { month: "long" });
    const day = date.toLocaleDateString("es-ES", { weekday: "long" });
    return day + ", " + date.getDate() + " " + month;
  }

  getStatusExecution(code: string): boolean {
    return code === "EXG" || code === "EXD";
  }

  isCommodityWithDetail(order: Order) {
    const validCommodities = ["DELIVERY", "DELIVERY_CHANGE", "PICKUP"];
    return validCommodities.includes(order.commodity.name);
  }

  showDetail(item: Order): string | Array<any> {
    switch (item.commodity.name) {
      case "DELIVERY":
      case "DELIVERY_CHANGE": {
        return this.getTotalDenominations(item);
      }
      case "PICKUP": {
        return this.getRequestedCurrencies(item);
      }
      default:
        return "";
    }
  }
}
export default CardDetail;
