
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import TimePicker from "@/orders/components/TimePicker/index.vue";
import {
  Day,
  Frequency,
  FrequencyDays,
  FrequencyDetail,
  FrequencyTypeCode,
  OrderCommodities,
  OrderDateDTO,
  OrderDTO,
  OrderProgrammingHour,
  OrderProgrammingHourType,
} from "@/orders";
import DateTimeMixin from "@/mixins/datetime";
import { LocalDate } from "@js-joda/core";

@Component({
  mixins: [DateTimeMixin],
  components: {
    Icon,
    TimePicker,
  },
})
export default class StepThree extends Vue {
  @Prop({ required: true }) value!: OrderDateDTO;
  // Props
  @Prop({ default: false }) active!: boolean;
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  @Prop({ required: true }) selectedDayInCalendar!: Day;

  // Data
  nowDate: Date = this.getTodayDate();
  left: string = require("@/orders/assets/icons/icon-chevron-left.svg");
  down: string = require("@/orders/assets/icons/icon-chevron-down.svg");
  hourStart = "00:00";
  hourEnd = "23:59";
  minDate = "";
  pad = (num: number) => num.toString().padStart(2, "0");
  frequency: Frequency | null = null;
  frequencyDays: FrequencyDays[] = [];
  availableDay = true;
  serviceDate: string | null = null;
  initialTime = "00:00";
  finalTime = "23:59";
  hourSelected: OrderProgrammingHourType = OrderProgrammingHourType.INDICATE_DAY;

  readonly PATTERN_YYYY_MM_DD = "yyyy-MM-dd";
  readonly hourTypes: OrderProgrammingHour[] = [
    { type: OrderProgrammingHourType.INDICATE_DAY, description: "Indicar el día" },
    { type: OrderProgrammingHourType.FIRST_PROGRAMMED_SERVICE, description: "El primer servicio programado" },
    { type: OrderProgrammingHourType.PLUS_48_HOURS, description: "48 horas" },
    { type: OrderProgrammingHourType.PLUS_72_HOURS, description: "72 horas" },
  ];

  // Métodos
  prevPage(): void {
    this.$emit("prevPage", true);
  }

  enabledNext(): boolean {
    return !!this.serviceDate && this.availableDay;
  }

  selectDate(): void {
    if (this.enabledNext()) {
      const serviceDateRaw = this.convertDateStringYYYYMMDDToDate(this.serviceDate!);
      const newValue = new OrderDateDTO(
        serviceDateRaw,
        this.convertHourSelectedToDateSelected(this.initialTime, serviceDateRaw),
        this.convertHourSelectedToDateSelected(this.finalTime, serviceDateRaw),
        this.hourSelected
      );
      this.$emit("selectDate", newValue);
    } else {
      console.error("Es necesario seleccionar una fecha y hora.");
    }
  }

  convertHourSelectedToDateSelected(hourAndMinute: string, serviceDate: Date): Date {
    const HOUR_POSITION = 0;
    const MINUTE_POSITION = 1;

    const hourAndMinutes: string[] = hourAndMinute.split(":", 2);

    const hourSelectedToDateSelected: Date = new Date(serviceDate!.getTime());
    hourSelectedToDateSelected.setHours(parseInt(hourAndMinutes[HOUR_POSITION]));
    hourSelectedToDateSelected.setMinutes(parseInt(hourAndMinutes[MINUTE_POSITION]));

    return hourSelectedToDateSelected;
  }

  selectHourType(item: number): void {
    const nowDatePlus2Days: Date = this.addDaysToDate(this.nowDate, 2);
    const nowDatePlus3Days: Date = this.addDaysToDate(this.nowDate, 3);

    switch (item) {
      case OrderProgrammingHourType.INDICATE_DAY:
        this.serviceDate = null;
        break;
      case OrderProgrammingHourType.FIRST_PROGRAMMED_SERVICE:
        this.serviceDate = this.formatDate(this.nowDate, this.PATTERN_YYYY_MM_DD)!;
        break;
      case OrderProgrammingHourType.PLUS_48_HOURS:
        this.serviceDate = this.formatDate(nowDatePlus2Days, this.PATTERN_YYYY_MM_DD)!;
        break;
      case OrderProgrammingHourType.PLUS_72_HOURS:
        this.serviceDate = this.formatDate(nowDatePlus3Days, this.PATTERN_YYYY_MM_DD)!;
        break;
      default:
        console.error("Hour Selected not allowed");
        return;
    }
    this.hourSelected = item;
  }

  @Watch("serviceDate", { immediate: true })
  verifyDateForFrequencyDays(): void {
    if (!this.serviceDate) {
      return;
    }

    const selectedOrderDate: LocalDate = LocalDate.parse(this.serviceDate);
    const nowLocalDate: LocalDate = LocalDate.now();

    if (selectedOrderDate.isBefore(nowLocalDate)) {
      return;
    }
    const frequencyTypeCode = selectedOrderDate.isEqual(nowLocalDate)
      ? FrequencyTypeCode.DAYS_SPECIAL
      : FrequencyTypeCode.EVENTUAL;

    const frequencyDetail = this.frequency?.frequencyDetails.find(
      (frequencyDetail: FrequencyDetail) => frequencyTypeCode === frequencyDetail.frequencyType.code
    );

    if (!frequencyDetail) {
      this.resetHours();
      this.availableDay = false;
      this.showFrequencyInvalid();
      return;
    }

    this.frequencyDays = frequencyDetail.frequencyDays;

    const dayOfWeek = selectedOrderDate.dayOfWeek().value();
    const frequencyDayByDayOfWeek: FrequencyDays = this.frequencyDays.find(
      (frequencyDay: FrequencyDays) => frequencyDay.day === dayOfWeek
    )!;

    this.availableDay = Boolean(frequencyDayByDayOfWeek);

    if (!this.availableDay) {
      this.resetHours();
      this.showFrequencyInvalid();
      return;
    }

    const frequencyDayHourStart = frequencyDayByDayOfWeek.hourStart;
    const frequencyDayHourEnd = frequencyDayByDayOfWeek.hourEnd;

    this.hourStart = frequencyDayHourStart;
    this.hourEnd = frequencyDayHourEnd;
    this.initialTime = frequencyDayHourStart;
    this.finalTime = frequencyDayHourEnd;
  }

  async created(): Promise<void> {
    await this.setFrequencies();
    await this.initProgrammingHourData();
  }

  async setFrequencies(): Promise<void> {
    const servicePointId = this.notChangeOrder.servicePoint.id;

    if (servicePointId) {
      const commodityById: OrderCommodities = await this.$services.commodities
        .fetchCommodities(servicePointId)
        .then((commodities) => commodities.find((commodity) => commodity.id === this.notChangeOrder.commodity.id)!);

      const contractCode = commodityById.contract.code;
      this.frequency = await this.$services.order.fetchFrequencies(contractCode);
    }
  }

  async initProgrammingHourData(): Promise<void> {
    const baseOrder = this.value;

    const isUpdateProcess: boolean =
      !!baseOrder.serviceDate && !!baseOrder.requestInitialTime && !!baseOrder.requestFinalTime;

    if (isUpdateProcess) {
      this.serviceDate = baseOrder.serviceDateString.split("T")[0];
      const initialTime = baseOrder.requestInitialTimeString.split("T")[1];
      const finalTime = baseOrder.requestFinalTimeString.split("T")[1];

      const initialTimeFromServiceDate = this.formatHour(this.serviceDate, initialTime);
      const finalTimeFromServiceDate = this.formatHour(this.serviceDate, finalTime);

      this.hourStart = initialTimeFromServiceDate;
      this.hourEnd = finalTimeFromServiceDate;
      this.initialTime = initialTimeFromServiceDate;
      this.finalTime = finalTimeFromServiceDate;
      this.hourSelected = baseOrder.typeDate;
    } else {
      // Establecer la fecha mínima como la fecha de hoy
      this.minDate = this.formatDate(this.nowDate, this.PATTERN_YYYY_MM_DD)!;

      const serviceDate: Date = this.selectedDayInCalendar
        ? new Date(this.selectedDayInCalendar.year, this.selectedDayInCalendar.month, this.selectedDayInCalendar.number)
        : this.nowDate;

      this.serviceDate = this.formatDate(serviceDate, this.PATTERN_YYYY_MM_DD)!;

      this.verifyDateForFrequencyDays();
    }
  }

  resetHours(): void {
    this.hourStart = "00:00";
    this.hourEnd = "23:59";
    this.initialTime = "00:00";
    this.finalTime = "23:59";
  }

  formatHour(dateBase: string, time: string): string {
    const timeWithoutZone = this.evaluateString(time);
    const s = `${dateBase}T${timeWithoutZone}`;
    const date = new Date(s);
    return `${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`;
  }

  evaluateString(time: string) {
    let s: string;
    const foundCharacterSpecial = "+";
    const foundCharacter = time.indexOf(foundCharacterSpecial);
    const notFound = -1;
    if (foundCharacter == notFound) {
      s = time;
    } else {
      s = time.substring(0, foundCharacter);
    }
    return s;
  }

  showFrequencyInvalid() {
    const message = this.$i18n.t("orders.message.frequencyDayInvalid") as string;
    const title = this.$i18n.t("orders.title.warning") as string;
    Vue.swal({
      title,
      text: message,
      icon: "warning",
    });
  }
}
