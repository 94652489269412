
import { Component, Vue } from "vue-property-decorator";
import Modal from "./Modal.vue";
import { Action } from "vuex-class";
import {PCol, PRow} from "@/common/components";

@Component({
  name: "session-expired-modal",
  components: {
    Modal,
    PRow,
    PCol,
  },
})
export class SessionExpiredModal extends Vue {
  @Action("logout") logout!: () => Promise<void>;

  timeOutId: ReturnType<typeof setInterval> | null = null;
  timerCount = 60;

  constructor() {
    super();
  }

  count(): void {
    this.timeOutId = setInterval(() => {
      this.timerCount = this.timerCount - 1;
      if (this.timerCount === 0) this.logout();
    }, 1000);
  }

  clear(): void {
    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }
    this.timerCount = 60;
  }
}
export default SessionExpiredModal;
